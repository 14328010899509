import React from "react";
import { useState } from 'react';
import "./Projects.scss";
import { Modal } from "react-bootstrap";
import CloseIcon from "../assets/closeIcon";

const FullPageImagePopup = () => {

  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="thumbnail-modal exhibition-invitation-modal"
      size="xl"
    >
      <Modal.Body>
        <button
          type="button"
          className="btn btn-icon close-button border-0 bg-transparent"
          onClick={() => setShow(false)}
        >
          <CloseIcon />
        </button>

        <div>
          <img
            className="img-fluid"
            src="../images/ever-given-invitation.jpg"
            alt="Ever Given Exhibition Invitation"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FullPageImagePopup;
